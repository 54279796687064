import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import './plugins'
import 'vue2-editor'
import VueMeta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'
import VueAnalytics from 'vue-analytics'
import excel from 'vue-excel-export'
import { VueMaskDirective } from 'v-mask'

Vue.directive('mask', VueMaskDirective)

Vue.use(excel)
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-173384593-1',
  router
})

Vue.use(VueClipboard)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

export const bus = new Vue()

Vue.config.productionTip = true

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
