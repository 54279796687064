<template>
  <div id="app">
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </div>
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'SEEB',
      titleTemplate: '',
      htmlAttrs: { lang: 'pt-br' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
  }
</script>

<style lang="sass">

  #app
    font-family: Roboto,sans-serif
    font-size: 1rem
    font-weight: 400
    line-height: 1.5
    color: #7d7d7d
    background-color: #fbfbfb
    overflow-x: hidden

  .input--is-focused
   border-color: red !important

  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important

    

</style>
