// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem('user-token')) {
    next()
    return
  }
  next('/login')
}

const initCms = (to, from, next) => {
  next('/pages/news')
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/web/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/web/pages/home/Index.vue'),
          meta: {
            title: 'SEEB | Home',
            metaTags: [
              {
                name: 'description',
                content: 'Sindicato dos Bancarios Uberlândia'
              },
              {
                property: 'og:description',
                content: 'Sindicato dos Bancarios Uberlândia'
              }
            ]
          }
        },{
          path: 'agreements',
          name: 'Acordos e Convenções',
          component: () => import('@/views/web/pages/agreements/Index.vue'),
        },{
          path: 'news',
          name: 'Notícias',
          component: () => import('@/views/web/pages/news/Index.vue'),
        },{
          path: 'post/news/:id',
          name: 'Post',
          component: () => import('@/layouts/web/Post.vue'),
        },{
          path: 'post/covenants/:id',
          name: 'PostCovenants',
          component: () => import('@/layouts/web/PostCovenants.vue'),
        },{
          path: 'gallery',
          name: 'Galeria',
          component: () => import('@/views/web/pages/gallery/Index.vue'),
        },{
          path: 'contact',
          name: 'Contato',
          component: () => import('@/views/web/pages/contact/Index.vue'),
        },{
          path: 'seja-socio',
          name: 'Seja sócio',
          component: () => import('@/views/web/pages/associate/Index.vue'),
        },{
          path: 'club',
          name: 'Clube',
          component: () => import('@/views/web/pages/club/Index.vue'),
        },{
          path: 'covenants',
          name: 'Convênios',
          component: () => import('@/views/web/pages/covenants/Index.vue')
        },{
          path: 'cateira-convenio',
          name: 'Carteira Convênio',
          component: null,
          openInNewTab: true
        }
      ],
    },
    {
      path: '/login',
          name: 'Login',
          component: () => import('@/layouts/login/Index.vue'),
    },{
      path: '/auth/resetPassword/:token',
          name: 'Alterar Senha',
          component: () => import('@/layouts/login/Index.vue'),
    },


    {
      path: '/votation/:id',
          name: 'Votation',
          component: () => import('@/layouts/votation/Index.vue'),
    },
    {
      path: '/cms',
      component: () => import('@/layouts/cms/Index.vue'),
      beforeEnter: ifAuthenticated,
      children: [
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/cms/pages/dashboard/Index.vue'),
          beforeEnter:initCms
        },
        {
          name: 'Usuarios',
          path: '/pages/user',
          component: () => import('@/views/cms/pages/user/Index.vue'),

        },{
          name: 'Criar Usuario',
          path: '/pages/user/create',
          component: () => import('@/views/cms/pages/user/CreateEditUser.vue')
        },{
          name: 'Editar Usuario',
          path: '/pages/user/edit/:id',
          component: () => import('@/views/cms/pages/user/CreateEditUser.vue')
        },{
          name: 'Notícias',
          path: '/pages/news',
          component: () => import('@/views/cms/pages/news/Index.vue')
        },{
          name: 'Criar notícia',
          path: '/pages/news/create',
          component: () => import('@/views/cms/pages/news/CreateEditNews.vue'),
        },{
          name: 'Editar notícia',
          path: '/pages/news/edit/:id',
          component: () => import('@/views/cms/pages/news/CreateEditNews.vue'),
        },{
          name: 'Categoria',
          path: '/pages/category',
          component: () => import('@/views/cms/pages/category/Index.vue')
        },{
          name: 'Galeria',
          path: '/pages/gallery',
          component: () => import('@/views/cms/pages/gallery/Index.vue'),
        },{
          name: 'Criar galeria',
          path: '/pages/gallery/create',
          component: () => import('@/views/cms/pages/gallery/CreateEditGallery.vue'),
        },{
          name: 'Editar galeria',
          path: '/pages/gallery/edit/:id',
          component: () => import('@/views/cms/pages/gallery/CreateEditGallery.vue'),
        },{
          name: 'Editar galeria',
          path: '/pages/gallery/create',
          component: () => import('@/views/cms/pages/gallery/CreateEditGallery.vue'),
        },{
          name: 'Acordos e Convenções',
          path: '/pages/agreements',
          component: () => import('@/views/cms/pages/agreements/Index.vue'),
        },{
          name: 'Editar Acordos e Convenções',
          path: '/pages/agreements/edit/:id',
          component: () => import('@/views/cms/pages/agreements/CreateEditAgreements.vue'),
        },{
          name: 'Criar Acordos e Convenções',
          path: '/pages/agreements/create',
          component: () => import('@/views/cms/pages/agreements/CreateEditAgreements.vue'),
        },{
          name: 'Club',
          path: '/pages/club',
          component: () => import('@/views/cms/pages/club/Index.vue'),
        },{
          name: 'Convênios',
          path: '/pages/covenants',
          component: () => import('@/views/cms/pages/covenants/Index.vue'),
        },{
          name: 'Criar convênio',
          path: '/pages/covenants/create',
          component: () => import('@/views/cms/pages/covenants/CreateEditCovenants.vue'),
        },{
          name: 'Editar convênio',
          path: '/pages/covenants/edit/:id',
          component: () => import('@/views/cms/pages/covenants/CreateEditCovenants.vue'),
        },{
          name: 'Contatos',
          path: '/pages/contacts',
          component: () => import('@/views/cms/pages/contacts/Index.vue'),
        },{
          name: 'Votações',
          path: '/pages/voting',
          component: () => import('@/views/cms/pages/voting/Index.vue'),
        },{
          name: 'Criar votação',
          path: '/pages/voting/create',
          component: () => import('@/views/cms/pages/voting/CreateEditVoting.vue'),
        },{
          name: 'Editar votação',
          path: '/pages/voting/edit/:id',
          component: () => import('@/views/cms/pages/voting/CreateEditVoting.vue'),
        },{
          name: 'Funcionarios do Banco',
          path: '/pages/bankEmployees',
          component: () => import('@/views/cms/pages/bankEmployees/Index.vue'),
        },{
          name: 'Associados',
          path: '/pages/associate',
          component: () => import('@/views/cms/pages/associate/Index.vue'),
        }
      ],
    },
  ],
})

export default router
